import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/pages/veterans.scss"
// import ImageSlider from "../components/ImageSlider/ImageSlider"

const SmileForVeteransPage = () => {
  return (
    <Layout language="en">
      <SEO
        title="Smiles For Veterans Program"
        description="Smiles For Veterans Program at Round Rock Oral Surgery and Dental Implants"
      />
      <div className="sfv">
        <div className="sfv__hero sfv__section">
          <img
            className="sfv__hero-img--desktop"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smiles-for-veterans-desktop-hero-image"
            alt="smiles for soliders banner"
          />
          <img
            className="sfv__hero-img--mobile"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smiles-for-veterans-mobile-hero-image"
            alt="smiles for soliders banner"
          />
        </div>

        <div className="sfv__body sfv__section">
          <div className="sfv__container small">
            <h1>SUPPORTING THOSE WHO SERVE</h1>

            <p>
              The Round Rock Oral Surgery & Dental Implants team is proud to
              announce that this year’s Smiles For Veterans recipient is Karl
              from Austin!
            </p>
            <p>
              Karl, 44, is a disabled veteran who faces daily struggles related
              to his dental health. With missing molars and an incomplete bottom
              row, his teeth are in poor condition, which makes eating difficult
              and causes frequent pain. As a result, Karl feels embarrassed to
              smile and often avoids it, which has affected his self-esteem. In
              his application, Karl also mentioned the emotional challenges he
              faces from his time in combat, which continue to affect him.
              Despite these obstacles, he believes restoring his smile would
              significantly improve his confidence and quality of life.
            </p>
            <p>
              We hope that providing Karl with a smile restoration will not only
              ease his physical discomfort but also help him regain the
              self-esteem and happiness he deserves.
            </p>
            <h2 style={{ fontSize: "24px" }}>FULL-ARCH RESTORATION</h2>
            <p>
              Karl will receive a full set of customized, artificial teeth at no
              cost. This tooth replacement solution is called full-arch
              restoration because it replaces a full dental arch of missing or
              failing teeth. Our team of experts will work closely with a
              restorative dentist to complete the treatment.{" "}
            </p>
          </div>
        </div>

        {/* <div className="sfv__section">
          <div className="sfv__container">
            <ImageSlider
              adaptiveHeight
              useDots
              images={[
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-1",
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-2",
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-3",
                "RCOMS/DEV/smiles-for-veterans-recipient-2023-4"
              ]}
            />
          </div>
        </div> */}

        <div className="sfv__section">
          <div className="sfv__container small">
            <p>
              Follow this page,{" "}
              <a
                href="https://www.facebook.com/people/Round-Rock-Oral-Surgery/100088232610744/"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>
              , and{" "}
              <a
                href="https://www.instagram.com/roundrockoralsurgery/"
                title="Follow us on Instagram"
                target="_blank">
                Instagram
              </a>{" "}
              to follow Karl’s journey to a new smile!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/procedures/dental-implants-round-rock-tx/"
                  title="Learn more about dental implants">
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/procedures/full-arch-restoration/"
                  title="Learn more about full-arch-restoration">
                  full-arch restoration
                </Link>{" "}
                to see how they can improve your oral health and confidence.
                Whether you need to replace one tooth or an entire arch of
                teeth, our team has a solution for you.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SmileForVeteransPage
